<!--
 * @Author: 月魂
 * @Date: 2021-11-19 16:29:45
 * @LastEditTime: 2022-09-20 16:36:47
 * @LastEditors: 月魂
 * @Description: 日程列表页面(日程活动版块可以点击进入的列表页，暂时保留)
 * @FilePath: \silk-road\src\views\Schedule.vue
-->
<template>
  <div class="box">
    <div class="cont">
      <van-nav-bar
        :title="$t('schedule.title') + date"
        left-arrow
        @click-left="handleBack"
      />
      <!-- <div class="main" @click="jump">
        <div class="header">
          <div>
            <div class="title">{{ this.$t('intro.name') }}</div>
            <div class="subTitle">{{ this.$t('schedule.title') }} {{ this.month }}/{{ this.date }}</div>
          </div>
        </div>
      </div> -->

      <div class="list">
        <div class="item" @click="jump(schedule)" v-for="schedule in list" :key="schedule.id">
          <div class="top">
            <div class="img">
              <img :src="schedule.imgUrl" alt="" class="dateItem">
            </div>
            <!-- 此处取简介第一段作为简短介绍，后续如若不合适，需专门添加介绍 -->
            <div class="des">{{ schedule.des[0] }}</div>
          </div>
          <div class="bottom">
            <div class="name">{{ schedule.title }}</div>
            <div class="origin">{{ schedule.team }}</div>
            <div class="time">{{ schedule.time }}</div>
            <div class="address">{{ schedule.address }}</div>
          </div>
        </div>
      </div>

      <!-- <div class="timeline">
        <el-timeline>
          <el-timeline-item v-for="(item, index) in list" :key="index" :timestamp="item.time" placement="top">
            <el-card @click.native="jump(item)">
              <h3 class="title">{{ item.title }}</h3>
              <p class="des">{{ item.des[0] }}</p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div> -->
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { NavBar } from 'vant'
import dayjs from 'dayjs'
import { handleBack } from '../utils/util'
Vue.use(NavBar)

export default {
  name: 'Schedule',
  data() {
    return {
      date: '',
      now: '12-1',
      list: []
    }
  },
  mounted() {
     // 计算演出列表，展览列表和论坛列表里的在本日的活动
    this.date = dayjs().format('YYYY-MM-DD');
    const pList = this.$t('perform.list');
    const eList = this.$t('exhibition.list');
    const aList = this.$t('activities.list');
    pList.forEach(item => {
      if (dayjs().isAfter(`${item.startTime} 00:00:00`) && dayjs().isBefore(`${item.endTime} 23:59:59`)) {
        item.showType = 'perform';
        this.list.push(item);
      }
    })
    eList.forEach(item => {
      if (dayjs().isAfter(`${item.startTime} 00:00:00`) && dayjs().isBefore(`${item.endTime} 23:59:59`)) {
        item.showType = 'exhibition';
        this.list.push(item);
      }
    })
    aList.forEach(item => {
      if (dayjs().isAfter(`${item.startTime} 00:00:00`) && dayjs().isBefore(`${item.endTime} 23:59:59`)) {
        item.showType = 'activity';
        this.list.push(item);
      }
    })
  },
  methods: {
    handleBack() {
      handleBack()
    },
    jump(params) {
      this.$router.push(`/${params.showType}-detail/${params.id}`)
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  min-height: 100vh;
  background-image: linear-gradient(to right, #ec6e5b, #b27c9e);
  .cont {
    max-width: 1200PX;
    margin: auto;
    .main {
      max-width: 1200PX;
      margin: auto;
      // background-image: url('../assets/index/schedule.png');
      background-size: 100%;
      display: flex;
      justify-content: center;
      padding: 20px 30px;
      padding-bottom: 0;
      .header {
        // background-image: url('../assets/index/perform.png');
        // background-size: cover;
        color: #fff;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        word-break: break-word;
        padding-bottom: 0;
        .title {
          width: 100%;
          font-size: 20px;
          text-align: center;
        }
        .subTitle {
          width: 100%;
          text-align: center;
          font-size: 20px;
        }
      }
      // .right {
      //   width: 50%;
      //   display: flex;
      //   align-items: center;
      //   img {
      //     width: 70%;
      //   }
      // }
    }
  }
  
  .list {
    max-width: 1200PX;
    margin: auto;
    display: flex;
    padding: 10px;
    padding-top: 0;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      width: 100%;
      padding: 10px;
      background-color: #fff;
      margin-top: 10px;
      .top {
        position: relative;
        .img {
          width: 100%;
          img {
            display: block;
            width: 100%;
          }
        }
        .des {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 0 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          background-color: rgba(0, 0, 0, 0.6);
          color: #fff;
          font-size: 14px;
        }
      }
      .bottom {
        display: flex;
        flex-wrap: wrap;
        .name {
          width: 100%;
          line-height: 2;
          font-size: 16px;
        }
        .origin {
          width: 100%;
          font-size: 14px;
          color: #9a9a9a;
        }
        .time {
          font-size: 12px;
          margin-right: 20px;
          color: #9a9a9a;
        }
        .address {
          font-size: 12px;
          color: #9a9a9a;
        }
      }
    }
    @media (min-width: 1200PX) {
      padding: 0;
      margin-top: 10px;
      .item {
        width: 49%;
      }
    }
  }
}
</style>
